import { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  Button,
  Pagination,
  Image,
  Space, Spin
} from "antd";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import DashbordSidebar from "../../components/DashboardSidebar";
import { Get } from "../../config/api/get";
import { STAY, UPLOADS_URL } from "../../config/constants/api";

const Dashboard = () => {
  const [paginationConfig, setPaginationConfig] = useState({
    pageNumber: 1,
    limit: 10,
    totalDocs: 0,
    totalPages: 0,
  });
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const userData = useSelector((state) => state.user.userData);
  const navigate = useNavigate();
  const [rooms, setRooms] = useState(null);
  const [loading, setLoading] = useState(true);
  const { Meta } = Card;
  const getMyRooms = (pageNumber, keyword) => {
    setLoading(true);
    if (pageNumber) {
      setPaginationConfig({
        ...paginationConfig,
        pageNumber: pageNumber,
      });
    }
    let params = {
      page: pageNumber
        ? pageNumber.toString()
        : paginationConfig.pageNumber.toString(),
      limit: paginationConfig.limit.toString(),
      name: keyword,
    };
    Get(`${STAY.getCompanyRooms}${userData?._id}`, token, params)
      .then((response) => {
        setRooms(response?.data?.docs);
        setPaginationConfig({
          pageNumber: response?.data?.page,
          limit: response?.data?.limit,
          totalDocs: response?.data?.totalDocs,
          totalPages: response?.data?.totalPages,
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error Fetching Rooms", err);
        setLoading(false);
      });
  };
  useEffect(() => {
    if (!profileDetails) {
      navigate("/createStayProfile");
    } else {
      getMyRooms();
    }
  }, []);

  const startIndex =
    (paginationConfig.pageNumber - 1) * paginationConfig.limit + 1;
  const endIndex = Math.min(
    startIndex + paginationConfig.limit - 1,
    paginationConfig.totalDocs
  );
  const message = rooms
    ? `Showing records ${endIndex} of ${paginationConfig.totalDocs}`
    : "Showing records 0 of 0";

  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-parent dashboard-right-card">
                        <Row align={"middle"}>
                          <Col lg={12}>
                            <h3 className="heading-28">Rooms Listing</h3>
                          </Col>
                          <Col lg={12}>
                            <div className="searchBox-with-button">
                              
                              <Button
                                type="link"
                                className="web-btn"
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                onClick={() => navigate("/addRoom")}
                              >
                                + ADD ROOM
                              </Button>
                            </div>
                          </Col>
                        </Row>
                        {!loading ? (
                          <Row gutter={16}>
                            {Array.isArray(rooms) && rooms.map((data, index)=>{
                              return  <Col xs={24} lg={8}>
                              <div>
                                <Space
                                  direction="vertical"
                                  size="middle"
                                  style={{
                                    width: "100%",
                                    padding: "8px",
                                  }}
                                >
                                  <div className="search-img">
                                    <Card
                                      className="card booking-card dropzone-card"
                                      cover={
                                        <span className="for-width">
                                          <Image
                                          style={{maxHeight:"230px", width:"100%"}}
                                            preview={false}
                                            alt={"Failed to load image"}
                                            src={UPLOADS_URL + data?.image}
                                            className=""
                                          />
                                        </span>
                                      }
                                    >
                                      <Meta
                                      style={{paddingBottom:"16px"}}
                                        title={data?.title  }
                                      />
                                      <Button
                                        type="link"
                                        className="web-btn"
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          width: "100%",
                                        }}
                                        onClick={() =>
                                          navigate(
                                            `/roomDetails/${data?._id}`
                                          )
                                        }
                                      >
                                        View Details
                                      </Button>
                                    </Card>
                                  </div>
                                </Space>
                              </div>
                            </Col>
                            })}
                          </Row>
                        ) : (
                          <Card>
                            <Col
                              xs={23}
                              md={21}
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                margin: "150px 0px",
                              }}
                            >
                              <Spin />
                            </Col>
                          </Card>
                        )}
                        <Row align="middle">
                          <Col span={24}>
                            <div
                              className="d-md-flex align-items-center justify-content-between"
                              style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                              }}
                            >
                              <p className="m-grey-text mb-0">{message}</p>
                              {(Array.isArray(rooms) && rooms.length>0) && <div aria-label="...">
                                <Pagination
                                  current={paginationConfig?.pageNumber}
                                  total={paginationConfig?.totalDocs}
                                />
                              </div>}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
