import { Col, Row, Image } from "antd";
import DashbordSidebar from "../../components/DashboardSidebar";
import dicon from "../../assets/d-icon.png";
import dicon2 from "../../assets/d-icon2.png";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { useEffect, useState } from "react";
import { Get } from "../../config/api/get";
import { STAY } from "../../config/constants/api";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

ChartJS.register(CategoryScale);
ChartJS.register(LinearScale);
ChartJS.register(PointElement);
ChartJS.register(LineElement);

const data = {
  labels: [
    "Nov 2015",
    "March 2016",
    "July 2017",
    "August 2018",
    "Sep 2019",
    "Oct 2020",
    "July 2021",
  ],
  datasets: [
    {
      label: "Users",
      data: [30000, 20000, 30000, 25000, 35000, 49000, 40000],
      fill: true,
      backgroundColor: "rgba(157,98,245,0.2)",
      borderColor: "#9D62F5",
      pointRadius: 3,
    },
  ],
};

const options = {
  maintainAspectRatio: false,
  responsive: true,
  scales: {
    y: {
      title: {
        display: true,
        text: "Users",
        color: "#000000",
      },
      min: 0,
      max: 50000,
    },
    x: {
      title: {
        display: true,
        text: "Months",
        color: "#000000",
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
  },
};

const Dashboard = () => {
  const [totalCustomers, setCustomers] = useState(null);
  const [totalRooms, setTotalRooms] = useState(null);
  const token = useSelector((state) => state.user.userToken);
  const profileDetails = useSelector((state) => state.user.profileDetails);
  const navigate = useNavigate();
  // useEffect(() => {
  //   if (profileDetails) {
  //     getDashboardInfo();
  //   } else {
  //     navigate("/create-dropzone-company");
  //   }
  // }, []);
  const getDashboardInfo = () => {
    Get(STAY.getDashboardInfo, token)
      .then((response) => {
        if (response?.status) {
          console.log(response);
          setCustomers(response?.data?.totalCustomers);
          setTotalRooms(response?.data?.totalRoomsBooked);
        }
      })
      .catch((err) => {
        console.log("Error fetching dashboard info ", err);
      });
  };
  return (
    <div className="shop-page">
      <Row style={{ width: "100%", justifyContent: "center" }}>
        <Col xs={23} md={21}>
          <div className="shop-page-main">
            <Row gutter={[16, 16]}>
              <Col xs={24} md={24} lg={24} xl={24}>
                <div className="my-account-profile">
                  <section className="side-menu-parent">
                    <DashbordSidebar />
                    <div className="about-us-section">
                      <div className="bg-parent dashboard-right-card">
                        <div className="">
                          <h3 className="main-heading">
                            Dashboard - Analytics and Reporting:
                          </h3>
                        </div>
                        <Row gutter={[20, 10]} style={{ background: "#fff" }}>
                          <Col xs={24} md={8}>
                            <div className="boxDetails analytics1">
                              <Row
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Col xs={7} md={8}>
                                  <div className="analyticsIcon">
                                    <Image
                                      src={dicon}
                                      alt="Analytics Image"
                                      preview={false}
                                    />
                                  </div>
                                </Col>
                                <Col xs={15} md={16}>
                                  <h4
                                    className="analyticsText"
                                    style={{ margin: 0 }}
                                  >
                                    Total Number of Customers
                                  </h4>
                                  <h3
                                    className="gray analyticsTextSmall"
                                    style={{ margin: 0 }}
                                  >
                                    {totalCustomers ? totalCustomers : 0}
                                  </h3>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                          <Col xs={24} md={8}>
                            <div className="boxDetails analytics1">
                              <Row
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Col xs={7} md={8}>
                                  <div className="analyticsIcon">
                                    <Image
                                      src={dicon2}
                                      alt="Analytics Image"
                                      preview={false}
                                    />
                                  </div>
                                </Col>
                                <Col xs={15} md={16}>
                                  <h4
                                    className="analyticsText"
                                    style={{ margin: 0 }}
                                  >
                                    Total Number Of Room Booked
                                  </h4>
                                  <h3
                                    className="gray analyticsTextSmall"
                                    style={{ margin: 0 }}
                                  >
                                    {totalRooms ? totalRooms : 0}
                                  </h3>
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>

                      <div
                        className="bg-parent dashboard-right-card"
                        style={{ margin: "20px 0" }}
                      >
                        <Row gutter={[20, 10]}>
                          <Col lg={12} xs={24}>
                            <div
                              className="boxDetails"
                              style={{ padding: "30px" }}
                            >
                              <Row
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Col xs={24} md={24}>
                                  <h3 className="sectionTitle">Customers</h3>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  minHeight: "400px",
                                  overflowX: "auto",
                                }}
                              >
                                <div
                                  style={{ minWidth: "600px", width: "100%" }}
                                >
                                  <Line options={options} data={data} />
                                </div>
                              </Row>
                            </div>
                          </Col>
                          <Col lg={12} xs={24}>
                            <div
                              className="boxDetails"
                              style={{ padding: "30px" }}
                            >
                              <Row
                                style={{
                                  width: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <Col xs={24} md={24}>
                                  <h3 className="sectionTitle">Room Booked</h3>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  minHeight: "400px",
                                  overflowX: "auto",
                                }}
                              >
                                <div
                                  style={{ minWidth: "600px", width: "100%" }}
                                >
                                  <Line options={options} data={data} />
                                </div>
                              </Row>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </section>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
